import { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { moveToEnableSmsScreen } from 'TwoFA/helpers/common';
import { getMobileNumber } from '../../helpers/api';
import StatusCard from './status_card';
import { showDisableSmsModal } from '../../helpers/disable_modal';
import withCombinedContext from '../hocs/with_combined_context';

class Sms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileNumber: '',
    };
  }

  componentDidMount() {
    const { enabledSms } = this.props;
    if (enabledSms) {
      getMobileNumber().then((reply) => {
        if (reply && reply.number) {
          this.setState({ mobileNumber: reply.number });
        }
      });
    }
  }

  showModal = () => {
    const { update } = this.props;
    showDisableSmsModal(update);
  };

  render() {
    const { mobileNumber } = this.state;
    const { enabledSms } = this.props;

    if (enabledSms == null) {
      return null;
    }

    return (
      <StatusCard
        enabled={enabledSms}
        iconClass="twofa-status__icon-sms"
        enabledInfo={mobileNumber}
        enabledTitle={t('javascript.twofa.settings.device_manager.status.sms.enabled.title')}
        disabledTitle={t('javascript.twofa.settings.device_manager.status.sms.disabled.title')}
        disabledSubtitle={t('javascript.twofa.settings.device_manager.status.sms.disabled.subtitle')}
        addCb={moveToEnableSmsScreen}
        editCb={moveToEnableSmsScreen}
        removeCb={this.showModal}
      />
    );
  }
}

Sms.propTypes = {
  enabledSms: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
};

export default withCombinedContext(Sms);
