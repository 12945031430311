import { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import { moveToPreferences, moveToEnableScreen } from 'TwoFA/helpers/common';
import { showDisableAllModal } from '../helpers/disable_modal';
import withCombinedContext from './hocs/with_combined_context';

class EnableDisableButton extends Component {
  onStatusChangeClick = () => {
    const { enabled } = this.props;
    if (enabled) {
      showDisableAllModal(moveToPreferences);
    } else {
      moveToEnableScreen();
    }
  };

  render() {
    const { enabled } = this.props;
    const buttonText = (isEnabled) => (isEnabled
      ? t('javascript.twofa.settings.toggle_button.disable')
      : t('javascript.twofa.settings.toggle_button.enable')
    );
    return (
      <div className="container-device-manager__container-change-button">
        <Button
          className="container-device-manager__change-button"
          secondary
          inverse={enabled}
          label={buttonText(enabled)}
          onClick={this.onStatusChangeClick}
        />
      </div>
    );
  }
}

EnableDisableButton.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default withCombinedContext(EnableDisableButton);
