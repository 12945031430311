import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { redirectToLogin } from 'TwoFA/helpers/common';
import StatusContainer from './status/status_container';
import DeviceList from './devicelist';
import Description from './description';
import EnableDisableButton from './enable_disable_button';
import StatusContext from '../contexts/status';
import withCallbacksContext from './hocs/with_callbacks_context';
import withStatus from '../../hocs/with_status';
import getStatusErrorModal from '../helpers/get_status_error_modal';

const Home = ({
  loaded,
  enabled,
  enabledTotp,
  enabledSms,
  refreshStatus: update,
}) => {
  useEffect(() => {
    if (enabled == null) {
      getStatusErrorModal();
    }
  }, []);

  const StatusContextValue = useMemo(
    () => ({
      enabled, enabledTotp, enabledSms, update,
    }), [enabled, enabledTotp, enabledSms, update],
  );

  if (loaded === false) {
    return null;
  }

  return (
    <div className="twofa__container">
      <StatusContext.Provider value={StatusContextValue}>
        <div className="bvs-card container-device-manager">
          <div className="container-device-manager__header">
            <div className="container-device-manager__phone-lock-icon" />
            <Description enabled={enabled} />
          </div>
          <hr />
          <StatusContainer />
          <DeviceList
            enabled={enabled}
            removeDevicesCallback={redirectToLogin}
            currentDeviceRemovedCallback={redirectToLogin}
          />
          <EnableDisableButton />
        </div>
      </StatusContext.Provider>
    </div>
  );
};

Home.propTypes = {
  loaded: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  enabledTotp: PropTypes.bool.isRequired,
  enabledSms: PropTypes.bool.isRequired,
  refreshStatus: PropTypes.func.isRequired,
};

export default withStatus(withCallbacksContext(Home));
