import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { moveToEnableTotpScreen } from 'TwoFA/helpers/common';
import StatusCard from './status_card';
import { showDisableTotpModal } from '../../helpers/disable_modal';
import withCombinedContext from '../hocs/with_combined_context';

const Totp = ({ update, enabledTotp }) => {
  const showModal = () => {
    showDisableTotpModal(update);
  };

  if (enabledTotp == null) {
    return null;
  }

  return (
    <StatusCard
      enabled={enabledTotp}
      iconClass="twofa-status__icon-totp"
      enabledTitle={t('javascript.twofa.settings.device_manager.status.totp.enabled.title')}
      disabledTitle={t('javascript.twofa.settings.device_manager.status.totp.disabled.title')}
      disabledSubtitle={t('javascript.twofa.settings.device_manager.status.totp.disabled.subtitle')}
      addCb={moveToEnableTotpScreen}
      removeCb={showModal}
    />
  );
};

Totp.propTypes = {
  enabledTotp: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
};

export default withCombinedContext(Totp);
