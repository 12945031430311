import { PureComponent } from 'react';
import get2FAStatus from '../helpers/api';

export default (WrappedComponent) => (class extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      enabled: false,
      enabledTotp: null,
      enabledSms: null,
      recommendedMethod: '',
    };
  }

  componentDidMount() {
    this.refreshStatus();
  }

  refreshStatus = () => {
    get2FAStatus().then((response) => {
      if (response && response.active !== undefined) {
        const enabledMethods = response.methods.reduce((acumulator, method) => (
          { ...acumulator, [method.type]: method.active }
        ), {});

        this.setState({
          loaded: true,
          enabled: response.active,
          enabledTotp: enabledMethods.totp,
          enabledSms: enabledMethods.sms,
          recommendedMethod: response.recommendation,
        });
      }
    });
  };

  render() {
    const {
      loaded,
      enabled,
      enabledTotp,
      enabledSms,
      recommendedMethod,
    } = this.state;

    return (
      <WrappedComponent
        {...this.props}
        loaded={loaded}
        enabled={enabled}
        enabledTotp={enabledTotp}
        enabledSms={enabledSms}
        recommendedMethod={recommendedMethod}
        refreshStatus={this.refreshStatus}
      />
    );
  }
});
