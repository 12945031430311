import { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { showSuccessMessage, showWarningMessage, showDangerMessage } from 'modal-helper';
import { redirectToLogin } from 'TwoFA/helpers/common';

import { fetchTrustedDevicesList, untrustDevice, untrustAllDevices } from '../helpers/api';
import Device from './device';

const { removeModal } = window.reduxState.store.actionDispatchers.modal;

class DeviceList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devicesList: [],
      currentDeviceId: '',
    };
  }

  componentDidMount() {
    const { enabled } = this.props;
    if (enabled) {
      this.getTrustedDevices();
    }
  }

  onDeviceRemove = (deviceId) => {
    removeModal();
    untrustDevice(deviceId).then((response) => {
      if (response && response.success && response.device_removed) {
        showSuccessMessage({
          title: t('javascript.twofa.settings.device_remove.current_removed'),
          actions: [
            {
              label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', inverse: true, primary: true, close: true,
            },
          ],
          onClose: redirectToLogin,
        });
      } else if (response && response.success) {
        this.getTrustedDevices();
      } else {
        showDangerMessage({
          title: t('javascript.twofa.error.general'),
          actions: [
            {
              label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
            },
          ],
          dataLayer: {
            error_message: t('javascript.twofa.error.general'),
            error_key: 'javascript.twofa.error.general',
            error_code: 'E0x009',
          },
        });
      }
    });
  };

  onRemoveAllClick = () => {
    showWarningMessage({
      title: t('javascript.twofa.settings.all_devices_remove.alert.title'),
      message: t('javascript.twofa.settings.all_devices_remove.alert.description'),
      actions: [
        {
          label: t('javascript.twofa.settings.all_devices_remove.alert.cta_accept'), id: 'yes-btn', warning: true, onClick: this.removeAllDevices,
        },
        {
          label: t('javascript.twofa.settings.all_devices_remove.alert.cta_cancel'), id: 'no-btn', inverse: true, warning: true, close: true,
        },
      ],
      dataLayer: {
        error_title: t('javascript.twofa.settings.all_devices_remove.alert.title'),
        error_message: t('javascript.twofa.settings.all_devices_remove.alert.description'),
        error_key: 'javascript.twofa.settings.all_devices_remove.alert.description',
        error_code: 'W0x003',
      },
    });
  };

  getTrustedDevices = () => {
    fetchTrustedDevicesList()
      .then((response) => {
        if (response && response.trustedDevices) {
          this.setState({
            devicesList: response.trustedDevices,
            currentDeviceId: response.currentDeviceId,
          });
        } else {
          showDangerMessage({
            title: t('javascript.twofa.error.general'),
            actions: [
              {
                label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
              },
            ],
            dataLayer: {
              error_message: t('javascript.twofa.error.general'),
              error_key: 'javascript.twofa.error.general',
              error_code: 'E0x00A',
            },
          });
        }
      });
  };

  removeAllDevices = () => {
    removeModal();
    untrustAllDevices().then((response) => {
      if (response && response.success) {
        showSuccessMessage({
          title: t('javascript.twofa.settings.all_devices_remove.removed_successfully'),
          actions: [
            {
              label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', inverse: true, primary: true, close: true,
            },
          ],
          onClose: redirectToLogin,
        });
      } else if (response && response.devices_removed) {
        this.getTrustedDevices();
        showDangerMessage({
          title: 'twofa.error.devices_removed_error_on_logout',
          actions: [
            {
              label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
            },
          ],
          dataLayer: {
            error_message: t('twofa.error.devices_removed_error_on_logout'),
            error_key: 'twofa.error.devices_removed_error_on_logout',
            error_code: 'E0x00B',
          },
        });
      } else {
        showDangerMessage({
          title: t('javascript.twofa.error.general'),
          actions: [
            {
              label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
            },
          ],
          dataLayer: {
            error_message: t('javascript.twofa.error.general'),
            error_key: 'javascript.twofa.error.general',
            error_code: 'E0x00C',
          },
        });
      }
    });
  };

  renderDevices = () => {
    const { devicesList, currentDeviceId } = this.state;

    return devicesList.map((device) => (
      <Device
        key={device.id}
        {...device}
        onConfirmDeviceRemove={this.onDeviceRemove}
        currentDeviceId={currentDeviceId}
      />
    ));
  };

  render() {
    const { devicesList } = this.state;
    const { enabled } = this.props;

    if (!enabled || devicesList.length === 0) {
      return null;
    }

    return (
      <div className="device-list-container">
        <div className="header-container">
          <p className="header-container__title">{t('javascript.twofa.settings.device_management')}</p>
          { devicesList.length > 0 && (
          <p className="header-container__remove-all-button" onClick={this.onRemoveAllClick}>
            {t('javascript.twofa.settings.all_devices_remove.label')}
          </p>
          ) }
          <hr className="device-list__top-horizontal-bar" />
        </div>
        <div className="device-list__main-list">
          { this.renderDevices() }
        </div>
        <hr className="horizontal-bar device-list__bottom-horizontal-bar" />
      </div>
    );
  }
}

DeviceList.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default DeviceList;
