import { getJSON, postJSON } from 'bv-fetch';

export const getMobileNumber = () => (
  getJSON('/bv_api/twofactorauth/get_formatted_number').catch(() => {})
);

export const fetchTrustedDevicesList = () => (
  getJSON('/bv_api/twofactorauth/get_trusted_devices_list').catch(() => {})
);

export const untrustDevice = (devId) => (
  postJSON('/bv_api/twofactorauth/untrust_device', { deviceId: devId }).catch(() => {})
);

export const untrustAllDevices = () => (
  postJSON('/bv_api/twofactorauth/untrust_all_devices', {}).catch(() => {})
);

export const disable2FA = (method) => (
  postJSON('/bv_api/twofactorauth/disable', { method }).catch(() => {})
);
