import CallbacksContext from '../../contexts/callbacks';
import StatusContext from '../../contexts/status';

export default (WrappedComponent) => (props) => (
  <CallbacksContext.Consumer>
    {(callbacks) => (
      <StatusContext.Consumer>
        {(status) => <WrappedComponent {...callbacks} {...status} {...props} />}
      </StatusContext.Consumer>
    )}
  </CallbacksContext.Consumer>
);
