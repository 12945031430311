import { t } from 'bv-i18n';
import { setFragment } from 'bv-helpers/location';
import { showDangerMessage } from 'modal-helper';

export default () => {
  showDangerMessage({
    title: t('javascript.twofa.error.general'),
    iconClosable: true,
    icon: false,
    onClose: () => { setFragment('preferences/verify_password_twofactor?first_modal=true'); },
    dataLayer: {
      error_message: t('javascript.twofa.error.general'),
      error_key: 'javascript.twofa.error.general',
      error_code: 'E0x00E',
    },
  });
};
