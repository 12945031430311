import PropTypes from 'prop-types';
import Totp from './totp';
import Sms from './sms';
import Disabled from './disabled';
import withStatusContext from '../hocs/with_status_context';

const StatusContainer = ({ enabled }) => {
  if (!enabled) {
    return <Disabled />;
  }

  return (
    <>
      <Totp />
      <Sms />
    </>
  );
};

StatusContainer.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default withStatusContext(StatusContainer);
