import { withModalWindow } from 'bv-hocs';
import Home from './components/home';
import CallbacksContext from './contexts/callbacks';

const DeviceManager = (props = {}) => (
  <CallbacksContext.Provider value={props}>
    <Home />
  </CallbacksContext.Provider>
);

export default withModalWindow(
  'javascript.twofa.navigation_title.settings',
  { className: 'two-factor-modal' },
)(DeviceManager);
